@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.MuiInputLabel-root {
  z-index: unset;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 0;
}

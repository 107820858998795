.columns_position {
    display: flex;
    justify-content: space-between;
}

.column_style_contract {
    display: flex;
    flex-direction: column;
}

.info_block {
    display: flex;
    width: 250px;
    border: 1px solid #FA7A45;
    padding: 0 15px;
    border-radius: 8px;
}

.title_contract_doc {
    font-size: 16px;
    color: #FA7A45;
    font-weight: 500;
}

.more_info {
    color: #5b5b5b;
}

.subtitle_contract_doc {
    font-size: 14px;
    color: #FA7A45;
    font-weight: 500;
}

.subtitle_payment {
    font-size: 15px;
    color: #FA7A45;
    font-weight: 500;
    display: flex;
    justify-content: center;
}

.tytle_contract_doc_contaner {
    width: 230px;
    font-size: 15px;
    color: #FA7A45;
    font-weight: 500;
}

.input_style_contract {
    display: flex;
    background-color: #fff;
    border: 1px solid #FA7A45;
    border-radius: 3px;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    padding: 12px;
    height: 14px;
    width: 300px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.button_position_contract_doc {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 100px;
}

.button_style_contract_doc {
    width: 120px;
    height: 45px;
    background-color: #FA7A45;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    border: none;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    border-radius: 5px;
}

.button_style_contract_doc:hover {
    background-color: #FF4B00;
}

.Doc_list {
    font-size: 12px;
    margin: 0;
    padding-top: 5px;
}

.label_doc {
    width: 150px;
    background-color: #FA7A45;
    color: white;
    padding: 10px 20px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.label_doc:hover {
    background-color: #FF4B00;
}

.info_and_education_container {
    padding-top: 10px;
    padding-bottom: 50px;
    border-bottom: 1px solid #FF4B00;
}

.center_block {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkbox_style_contract {
    padding-top: 30px;
    display: flex;
    justify-content: center;
}

.checkbox_style_passport_number {
    padding-top: 15px;
    padding-bottom: 15px;
    max-width: 325px;
}

.title_contract_info {
    font-size: 17px;
    color: #FA7A45;
    font-weight: 500;
}

.file-input {
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    background-color: #FA7A45;
    border-radius: 5px;
    padding: 6px 12px;
    cursor: pointer;
    color: white;
}

.file-input input[type="file"] {
    display: none;
}

.title_block {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 22px;
    color: #FF4B00;
}

.title_contract_section {
    display: flex;
    justify-content: center;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #FF4B00;
    font-weight: 500;
}

.title_studentName {
    display: flex;
    justify-content: center;
    font-size: 24px;
    margin-top: 20px;
    color: #FA7A45;
    font-weight: 500;
}

.elements_in_row {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    gap: 20px;
}
.filelist{
    margin: 20px 0;
    min-width: 200px;
}

.filelist_header{
    display: grid;
    grid-template-columns: 1fr 4fr 2fr 2fr;
}


.filelist_name{
    grid-column-start: 2;
}

.filelist_date{
    grid-column-start: 3;
    justify-self: center;
}

.filelist_size{
    grid-column-start: 4;
    justify-self: center;
}
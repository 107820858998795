.info_student_block {
    display: flex;
    flex-direction: column;
}

.student_field {
    display: flex;
    gap: 10px;
    align-items: center;

    h5 {
        font-size: 14px;
    }
}
.form_container{
    max-width: 1200px;
    display: flex;
    justify-content: center;
    margin: auto;
}

.header_greeting{
    width: 500px;
    position: absolute;
    top:15%;
}

.Login_Logo{
    display: flex;
    padding-left: 140px;
    padding-bottom: 20px;
}

.form_style{
    display: flex;
    flex-direction: column;
    padding: 90px 110px;
    background-color: #FA7A45;
    border-radius: 8px;
}

.label_style{
    display: block;
    margin-bottom: 0.25rem;
    padding-top: 20px;
    padding-bottom: 5px;
    font-size: 15px;
    font-weight: 500;
}

.input_style{
    display: block;
    width: 280px;
    padding: 5px 5px 5px 20px;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    border: 1px solid #FA7A45;
    border-radius: 10px;
    height: 35px;
}

.button_style_login{
    background-color: #FA7A45;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    border-radius: 5px;
    border:none;
    width: 110px;
    padding: 12px 20px;
    text-align: center;
    margin-top: 25px;
    font-family: 'Montserrat', sans-serif;
    cursor:pointer;
}

.button_style_login:hover{
    background-color: #FF4B00;
}

.checkbox_style_login{
    padding-top: 30px;
    font-size: 13px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif
}

.checkbox_style_login input{
    margin-top: 3px;
    margin-right: 7px;
}
.header {
  display: flex;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;

  max-width: 1500px;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
}

.header-top__logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-logo {
  width: 100%;
  height: 100%;

  margin-top: 55px;
}

.header-top__navigation {
  display: flex;
  flex-direction: row;
  margin-right: 15px;
  height: 170px;
  align-items: end;
}
  
  
/*Позиционирование*/
.nav {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 70px;
  display: flex;
  flex-direction: row;
}

@media (max-width: 700px) {
  .nav {
    row-gap: 20px;
    flex-direction: column;
  }
}

.filter_position {
  width: 240px;
  height: 40px;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/*Кнопка добавить студента*/
.add_student_btn {
  margin-left: 0;
  margin-right: auto;
  display: flex;
  text-decoration: none;
  font-size: 14px;
  color: #fff;
  padding: 8px 16px;
  font-family: 'Montserrat', sans-serif;
  background-color: #FA7A45;
  width: 180px;
  border-radius: 2px;
  align-items: center;
  justify-content: space-between;
  border: none;
}

.add_student_btn:hover {
  background-color: #FF4B00;
}

.file_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 160px;
  margin: 5px 15px 5px 0;
  background-color: #FA7A45;
  border-radius: 5px;
  padding: 6px 12px;
  cursor: pointer;
  color: white;
  font-size: 14px;
}

.file_input input[type="file"] {
  display: none;
}



.notification_navbar{
    padding-top: 50px;
    display: flex;
    justify-content: left;
    width: 800px;
    margin-right: auto;
}

.add_notification_button{
    cursor: pointer;
    margin-left: 0;
    margin-right: auto;
    margin-top: -25px;
    display: flex;
    text-decoration: none;
    font-size: 15px;
    color: #fff;
    padding: 8px 16px;
    font-family: 'Montserrat', sans-serif;
    background-color: #FA7A45;
    width: 200px;
    border-radius: 2px;
    align-items: center;
    justify-content: space-between;
    border: none;
}

.add_notification_button:hover{
    background-color: #FF4B00;
}

.search_notification{
    display: flex;
}

.search_notification_input input{
    background-color: #fff;
    border: 1px solid #FA7A45;
    border-radius: 3px;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    padding: 12px;
    height: 14px;
    width: 250px;
    outline: none;
}

.icon_button{
    cursor: pointer;
}
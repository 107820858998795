.modal{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0);
    z-index: 1;
}

.modal.active{
    transform: scale(1);
}

.modal_content{
    padding: 20px;
    border-radius: 12px;
    background-color: #fff;
}

.form_style{
    background-color: #fff;
    width: 350px;
}

.button_style_registration{
    background-color: #FA7A45;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    border-radius: 5px;
    border:none;
    width: 200px;
    padding: 12px 20px;
    text-align: center;
    margin-top: 25px;
    font-family: 'Montserrat', sans-serif;
    cursor:pointer;
}

.button_style_registration:hover{
    background-color: #FF4B00;
}
.tableSearchBlockPosition {
  display: flex;
  gap: 10px;
  row-gap: 30px;
}

.dropChanges {
  display: flex;
  flex-direction: row;
}

.searchType {
  display: flex;
  flex-direction: row;
}

@media(max-width: 550px) {
  .searchType {
    flex-direction: column;
    row-gap: 15px;
  }
}

@media(max-width: 1160px) {
  .tableSearchBlockPosition {
    flex-direction: column;
  }
}

.searchPosition {
  display: flex;
  margin-right: auto;
}

.inputStyle {
  width: 210px;
  padding: 5px 5px 5px 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border: 1px solid #FA7A45;
  border-radius: 10px;
  height: 28px;
}

.date_picker_table {
  z-index: 2;
  width: 210px;
  height: 28px;

  padding: 5px 5px 5px 20px;

  font-size: 13px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;

  border: solid 1px #FA7A45;
  border-radius: 10px;
}

.date_picker:hover:enabled {
  border: solid 1px #212121;
}

.date_picker:focus:enabled {
  border: solid 1px #ED6C02;
}
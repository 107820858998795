.elementsInRow {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  gap: 20px;
}

.hoursNumber {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 325px;
}

.hoursInput {
  width: 165px;
  height: 50px;
}

.yearRangeInput {
  width: 135px;
}

.resultHoursNumber {
  color: #FA7A45
}

.resultHoursNumber span {
  color: #000;
}
.generatedFilesContainer {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  font-size: 24px;
  color: #FA7A45;
  font-weight: 500;
  margin-top: 24px;
  line-height: 1;
  margin-block-start: 0;
  margin-block-end: 0;
}

.filesBlock {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.filesBlock p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.sectionBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid #ffc9b0;
}

.sectionBlock:last-child {
  border: none;
}

.sectionTitle {
  font-size: 20px;
  color: #FA7A45;
  font-weight: 500;
}

.textWithIcon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fileSection {
  display: flex;
  flex-direction: row;
}

.textWithDescription {
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  border: 1px solid transparent;
}

.textWithDescription:hover {
  border-bottom: 1px solid #ffc9b0;
}

.description {
  opacity: 60%;
  font-size: 12px;
}

.downloadIcon {
  cursor: pointer;
  transition: 0.1s !important;
  color: #ffa983;
}

.downloadIcon:hover {
  color: #FA7A45;
}

.listDot {
  font-size: 24px;
  font-weight: 500;
}

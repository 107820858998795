.disk {
    padding-top: 20px;
}

.downloadGeneratedFiles {
    cursor: pointer;
    color: rgba(255, 75, 0, 0.7);
}

.disk_btns{
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 750px;
    justify-content: space-between;
    margin-bottom: 10px;
}

.disk_style_btns{
    text-align: center;
    padding: 5px 10px;
    
    border:2px solid #FF6347;
    background-color: #fff;
    border-radius: 5px;

    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    color: black;
    font-weight: 500;

    cursor: pointer;
}

.disk_style_btns:hover{
    color: #FF4B00;
}

.disk_style_bread {
    font-size: 15px;
    color: #FF6347;
}

.disk_data_label {
    font-weight: 450;
    margin-top: auto;
    margin-bottom: auto;
    border-bottom: 1px #FF4B00 solid;
}
